// src/components/Subscription.js
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import MobileMenu from './MobileMenu';
import SubscriptionPlan from './SubscriptionPlan';
import './styles/Subscription.css';

import { loadStripe } from '@stripe/stripe-js';

// Importa le icone corrette
import SchoolIcon from '@mui/icons-material/School';
import ResearchIcon from '@mui/icons-material/Search';
import QuizIcon from '@mui/icons-material/Quiz';
import ScienceIcon from '@mui/icons-material/Science'; // Sostituito LabIcon con ScienceIcon
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'; // MontessoriIcon
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'; // ReggioIcon
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // PDFIcon

import useGetSubscription from '../api/subscription/useGetSubscription';
import useActivateFreeTrial from '../api/subscription/useActivateFreeTrial';
import useCreateCheckoutSession from '../api/subscription/useCreateCheckoutSession';
// Rimosso: import useUpgradeToAnnual from '../api/subscription/useUpgradeToAnnual'; // Importa il nuovo hook

// Importa il nuovo hook per il Customer Portal
import useCustomerPortal from '../api/subscription/useCustomerPortal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Subscription = () => {
  // Definisci isLoggedIn come stato
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Utilizza gli hook per gestire le chiamate API
  const { subscription, loading: subscriptionLoading, error: subscriptionError, refetch: refetchSubscription } = useGetSubscription();
  const { activateTrial, loading: activateLoading, error: activateError, message: activateMessage } = useActivateFreeTrial();
  const { createSession, sessionId, loading: createSessionLoading, error: createSessionError } = useCreateCheckoutSession();
  // Rimosso: const { upgradeToAnnual, sessionId: upgradeSessionId, loading: upgradeLoading, error: upgradeError } = useUpgradeToAnnual(); // Usa il nuovo hook

  // Importa il nuovo hook
  const { openCustomerPortal, loading: portalLoading, error: portalError } = useCustomerPortal();

  // Effettua il refetch quando l'utente effettua il login
  useEffect(() => {
    if (isLoggedIn) {
      refetchSubscription();
    }
  }, [isLoggedIn, refetchSubscription]);

  // Log per debug
  useEffect(() => {
    console.log('Subscription data received:', subscription);
  }, [subscription]);

  const isPlanActive = (planType) => {
    return subscription?.subscription_type === planType && subscription.is_subscribed;
  };

  const handlePurchase = async (plan) => {
    if (!isLoggedIn) {
      window.location.href = '/login';
      return;
    }

    if (plan === 'Prova Gratuita') {
      // Utilizza l'hook per attivare la prova gratuita
      try {
        await activateTrial();
        refetchSubscription();
        alert('La prova gratuita è stata attivata con successo!');
      } catch (error) {
        alert(`Errore: ${activateError?.error || 'Si è verificato un errore durante l\'attivazione della prova gratuita.'}`);
      }
      return;
    }

    if (plan === 'Annuale' && isPlanActive('monthly')) {
      // Se l'utente ha un abbonamento mensile, reindirizza al Customer Portal
      try {
        await openCustomerPortal();
      } catch (error) {
        console.error('Errore durante il reindirizzamento al Customer Portal:', error);
        alert(`Errore durante l'upgrade: ${portalError?.error || 'Si è verificato un errore.'}`);
      }
      return;
    }

    // Altrimenti, gestisci come un acquisto normale
    let subscriptionType = '';
    if (plan === 'Mensile') {
      subscriptionType = 'monthly';
    } else if (plan === 'Annuale') {
      subscriptionType = 'annual';
    }

    try {
      const session = await createSession(subscriptionType);
      console.log('Session ID:', session);
      const stripe = await stripePromise;

      const result = await stripe.redirectToCheckout({
        sessionId: session,
      });

      if (result.error) {
        console.error('Errore durante il redirect a Stripe:', result.error.message);
        alert(`Errore durante il pagamento: ${result.error.message}`);
      }
    } catch (error) {
      console.error('Errore durante l\'acquisto:', error);
      alert(`Errore durante l'acquisto: ${createSessionError?.error || 'Si è verificato un errore.'}`);
    }
  };

  // Definizione delle funzionalità per ciascun piano
  const freeFeatures = [
    { icon: <SchoolIcon />, text: 'AI creazione attività didattiche' },
    { icon: <ResearchIcon />, text: 'AI ricerca nuove metodologie' },
    { icon: <QuizIcon />, text: 'AI creazione quiz' },
    { icon: <ScienceIcon />, text: 'AI creazione laboratorio' },
    { icon: <AssistWalkerIcon />, text: 'AI assistenza scrittura' },
    { icon: <EmojiPeopleIcon />, text: 'Metodo Montessori AI' },
    { icon: <EmojiEmotionsIcon />, text: 'Metodo Reggio Emilia AI' },
    { icon: <UploadIcon />, text: 'Accesso a caricamento dei file' },
    { icon: <DownloadIcon />, text: 'Funzionalità di download word e pdf' },
    { icon: <PictureAsPdfIcon />, text: 'Funzionalità con limitazione di 75mila parole processate' },
  ];

  const monthlyFeatures = [
    ...freeFeatures.slice(0, 9), // Le prime 9 funzionalità sono le stesse del piano gratuito
    { icon: <PictureAsPdfIcon />, text: 'Funzionalità con limitazione di 750mila parole processate' },
  ];

  const annualFeatures = [
    ...monthlyFeatures.slice(0, 10), // Le prime 10 funzionalità sono le stesse del piano mensile
    { icon: <PictureAsPdfIcon />, text: 'Omaggio PDF della Quadagenda di https://maestrainbluejeans.com/' },
    { icon: <PictureAsPdfIcon />, text: 'Funzionalità con limitazione di 750mila al mese di parole processate' },
  ];

  return (
    <Box
      sx={{
        fontFamily: 'Inter',
        color: 'text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);',
        minHeight: '100vh',
        background: 'linear-gradient(180deg, #DF4634 36%, #70231A 67.5%, #000 100%)',
        boxSizing: 'border-box',
        paddingTop: '1rem', // Aggiunto padding superiore
        paddingBottom: '1rem', // Aggiunto padding inferiore
      }}
    >
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn} // Passa setIsLoggedIn a Header
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.png"
      />

      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}

      <div className="container-sub">
        <Box
          sx={{
            textAlign: { xs: 'center', md: 'right' },
            textShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            fontFamily: 'Inter, sans-serif',
            fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' }, // Ridotto da 2rem, 3rem, 4rem a 1.75rem, 2.5rem, 3rem
            fontWeight: 900,
            lineHeight: '3rem', // Ridotto da 3.75rem a 3rem
            marginBottom: '1.5rem', // Ridotto da 2rem a 1.5rem
            padding: { xs: '0 1.5rem', md: '0 3rem' }, // Ridotto padding
            marginTop: '1.5rem', // Ridotto da 2rem a 1.5rem
            boxSizing: 'border-box',
          }}
        >
          Scegli il tuo piano <br /> ideale
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1.5rem', // Ridotto da 2rem a 1.5rem
            marginTop: '-1.5rem', // Ridotto da -2rem a -1.5rem
            gap: '1.5rem', // Ridotto da 2rem a 1.5rem
            boxSizing: 'border-box',
          }}
        >
          <SubscriptionPlan
            title="Prova Gratuita"
            features={freeFeatures}
            price="Gratis"
            onPurchase={() => handlePurchase('Prova Gratuita')}
            isActive={isPlanActive('free')}
            endDate={subscription?.subscription_end_date}
          />

          <SubscriptionPlan
            title="Mensile"
            features={monthlyFeatures}
            price="9.99 Euro"
            onPurchase={() => handlePurchase('Mensile')}
            isActive={isPlanActive('monthly')}
            endDate={isPlanActive('monthly') ? subscription?.subscription_end_date : null}
          />

          <SubscriptionPlan
            title="Annuale"
            features={annualFeatures}
            price="100 Euro"
            onPurchase={() => handlePurchase('Annuale')}
            isActive={isPlanActive('annual')}
            endDate={isPlanActive('annual') ? subscription?.subscription_end_date : null}
            // Rimosso: isMonthlyActive={isPlanActive('monthly')} // Passa lo stato dell'abbonamento mensile
          />
        </Box>
      </div>

      {/* Gestisci loading e error */}
      {(subscriptionLoading || activateLoading || createSessionLoading || portalLoading) && (
        <div className="loading-overlay">
          <p>Caricamento...</p>
        </div>
      )}

      {(subscriptionError || activateError || createSessionError || portalError) && (
        <div className="error-message">
          <p>
            {subscriptionError?.error ||
              activateError?.error ||
              createSessionError?.error ||
              portalError?.error ||
              'Si è verificato un errore.'}
          </p>
        </div>
      )}

      {activateMessage && (
        <div className="success-message">
          <p>{activateMessage}</p>
        </div>
      )}
    </Box>
  );
};

export default Subscription;
