import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const WelcomePage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#DF4634',
        color: '#FFFFFF',
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <img
        src="/logo/logonero.png"
        alt="Logo"
        style={{
          width: '83px',
          height: '76px',
          marginBottom: '40px',
        }}
      />
      <Typography
        variant="h4"
        component="h1"
        sx={{
          fontFamily: 'Inter, sans-serif',
          fontWeight: 600,
          fontSize: '31px',
          lineHeight: '27px',
        }}
        gutterBottom
      >
        Benvenuto a Minerva AI
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{
          fontFamily: 'Inter, sans-serif',
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: '27px',
        }}
        gutterBottom
      >
        Accedi o registrati con il tuo account MinervaAI
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          marginTop: '20px',
        }}
      >
        <Button
          variant="contained"
          component={Link}
          to="/login"
          sx={{
            backgroundColor: '#000000',
            color: '#FFFFFF',
            borderRadius: '30px',
            width: '100px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#333333',
            },
          }}
        >
          Accedi
        </Button>
        <Button
          variant="contained"
          component={Link}
          to="/signup"
          sx={{
            backgroundColor: '#000000',
            color: '#FFFFFF',
            borderRadius: '30px',
            width: '100px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#333333',
            },
          }}
        >
          Registrati
        </Button>
      </Box>
    </Box>
  );
};

export default WelcomePage;
