// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom'; // Importa e rinomina Link di react-router-dom
import { Box, Button, TextField, Typography, Link, Alert, Divider } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import useLogin from '../api/auth/useLogin'; // Importa il hook useLogin

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { login, loading, error } = useLogin(); // Destruttura le proprietà dal hook

  const handleLogin = async (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    try {
      console.log('Attempting to login...');
      await login(userData); // Utilizza il metodo login del hook

      setSuccess(true);
      // Reindirizza all'home dopo un breve ritardo
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Login failed:', error);
      setSuccess(false);
      // L'errore è già gestito dallo stato del hook
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F3F6FB',
        position: 'relative',
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <RouterLink to="/">
        <img
          src="/logo/logonero.png"
          alt="Logo"
          style={{
            width: '83px',
            height: '76px',
            marginBottom: '40px',
          }}
        />
      </RouterLink>
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 320,
          padding: 2,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
          Bentornato
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>Accesso completato! Reindirizzamento...</Alert>}
        <TextField
          label="Email address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            width: '320px',
            height: '64px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              '& fieldset': {
                borderColor: '#DF4634',
              },
              '&:hover fieldset': {
                borderColor: '#DF4634',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#DF4634',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#DF4634',
              fontFamily: 'Inter, sans-serif',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#DF4634',
            },
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            width: '320px',
            height: '64px',
            marginTop: '8px', // Reduced margin top
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              '& fieldset': {
                borderColor: '#DF4634',
              },
              '&:hover fieldset': {
                borderColor: '#DF4634',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#DF4634',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#DF4634',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#DF4634',
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading} // Disabilita il bottone durante il caricamento
          sx={{
            width: 320,
            height: 50,
            backgroundColor: '#DF4634',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '30px',
            textTransform: 'none',
            fontSize: '15px',
            lineHeight: '27px',
            color: '#FFFFFF',
            marginTop: '8px', // Reduced margin top
            '&:hover': {
              backgroundColor: '#DF4634',
            },
          }}
        >
          {loading ? 'Accesso in corso...' : 'Accedi'}
        </Button>
      </Box>
      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Non hai un account?{' '}
        <Link component={RouterLink} to="/signup" sx={{ color: '#DF4634', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
          Registrati
        </Link>
        <Typography variant="body2" sx={{ marginTop: 0.2 }}>
          Dimenticato la password?{' '}
          <Link component={RouterLink} to="/reset-password" sx={{ color: '#DF4634', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
            Reset-password
          </Link>
        </Typography>
      </Typography>
      <Divider sx={{ width: '100%', margin: '16px 0' }}>Oppure</Divider>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          width: 320,
          height: 52,
          borderColor: '#C3C8CF',
          borderRadius: '30px',
          textTransform: 'none',
          fontSize: '15px',
          lineHeight: '27px',
          color: '#2E3339',
          marginBottom: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#F3F6FB',
          },
        }}
        startIcon={<GoogleIcon />}
      >
        Continua con Google
      </Button>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          width: 320,
          height: 52,
          borderColor: '#C3C8CF',
          borderRadius: '30px',
          textTransform: 'none',
          fontSize: '15px',
          lineHeight: '27px',
          color: '#2E3339',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#F3F6FB',
          },
        }}
        startIcon={<MicrosoftIcon />}
      >
        Continua con Microsoft Account
      </Button>
    </Box>
  );
};

export default Login;
