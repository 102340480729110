// src/components/Profile/Profile.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, LinearProgress } from '@mui/material';
import Header from '../Header';
import MobileMenu from '../MobileMenu';
import './styles/Profile.css';
import ProfileDetails from './ProfileDetails';
import ProfileDocuments from './ProfileDocuments';

// Importa gli hook personalizzati
import useGetProfile from '../../api/profile/useGetProfile';
import useUpdateProfile from '../../api/profile/useUpdateProfile';
import useDeleteAccount from '../../api/profile/useDeleteAccount';

const Profile = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));

  // Utilizza gli hook per gestire le chiamate API
  const { profile, loading: profileLoading, error: profileError, refetch: refetchProfile } = useGetProfile();
  const { updateProfile, loading: updateLoading, error: updateError, message: updateMessage } = useUpdateProfile();
  const { deleteAccount, loading: deleteLoading, error: deleteError } = useDeleteAccount(); // Se necessario

  const [updatedProfile, setUpdatedProfile] = useState({});

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (profile) {
      setUpdatedProfile(profile);
    }
  }, [profile]);

  const handleSaveProfile = async () => {
    try {
      await updateProfile(updatedProfile);
      setIsEditing(false);
      alert('Profilo aggiornato con successo!');
      refetchProfile();
    } catch (error) {
      // L'errore è già gestito nello hook
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProfile((prev) => ({ ...prev, [name]: value }));
  };

  // Gestione opzionale della cancellazione dell'account
  const handleDeleteAccount = async () => {
    if (window.confirm('Sei sicuro di voler eliminare il tuo account? Questa azione è irreversibile.')) {
      try {
        await deleteAccount();
        alert('Account eliminato con successo.');
        setIsLoggedIn(false);
        navigate('/'); // Reindirizza all'home o alla pagina di login
      } catch (error) {
        // L'errore è già gestito nello hook
      }
    }
  };

  if (profileLoading) return <LinearProgress />;
  if (profileError) return <Typography color="error">Errore: {profileError}</Typography>;

  return (
    <Box className="profile-root">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#F44336"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.png"
      />

      {mobileMenuOpen && (
        <MobileMenu isLoggedIn={isLoggedIn} setMobileMenuOpen={setMobileMenuOpen} />
      )}

      <div className="container-profile">
        <Box className="profile-content">
          <Box className="profile-sections">
            <ProfileDetails
              profileData={profile}
              isEditing={isEditing}
              updatedProfile={updatedProfile}
              handleInputChange={handleInputChange}
              handleSaveProfile={handleSaveProfile}
              setIsEditing={setIsEditing}
              loading={updateLoading}
              error={updateError}
            />

            <ProfileDocuments />
          </Box>
        </Box>
      </div>

      {/* Gestione dei messaggi di caricamento e errore */}
      {(profileLoading || updateLoading || deleteLoading) && (
        <div className="loading-overlay">
          <p>Caricamento...</p>
        </div>
      )}

      {(profileError || updateError || deleteError) && (
        <div className="error-message">
          <p>
            {profileError ||
              updateError ||
              deleteError ||
              'Si è verificato un errore.'}
          </p>
        </div>
      )}

      {updateMessage && (
        <div className="success-message">
          <p>{updateMessage}</p>
        </div>
      )}
    </Box>
  );
};

export default Profile;
