// AgentStart.js
import React from 'react';
import { Box, Typography, Grid, Paper, Button, IconButton, Divider, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const primaryAgents = [
  {
    name: 'Metodologie',
    description: 'Scopri metodologie educative, con consigli su risorse e spiegazioni.',
    image: '/people/metodologie.svg',
    agentKey: 'metodologie',
  },
  {
    name: 'Attività',
    description: 'Crea attività didattiche innovative per la tua classe.',
    image: '/people/attività.svg',
    agentKey: 'attività',
  },
  {
    name: 'Quiz',
    description: 'Genera quiz stimolanti per diversi livelli di apprendimento.',
    image: '/people/quiz.svg',
    agentKey: 'quiz',
  },
  {
    name: 'Laboratori e progetti',
    description: 'Perfetto per ideare e sviluppare laboratori o progetti stimolanti',
    image: '/people/laboratorio.svg',
    agentKey: 'laboratori',
  },
];

const secondaryAgents = [
  {
    name: 'Montessori',
    description: 'Scopri il Metodo Montessori per un apprendimento personalizzato.',
    image: '/people/montessori.png',
    agentKey: 'montessori',
  },
  {
    name: 'Malaguzzi',
    description: 'Approfondisci il Metodo Reggio Emilia per lo sviluppo creativo.',
    image: '/people/malaguzzi.png',
    agentKey: 'malaguzzi',
  },
];

const AgentStart = ({ open, onClose, handleNewChat }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '95%', sm: '80%', md: '60%' },
        backgroundColor: '#FFFFFF',
        border: '1px solid #DF4634', // Bordo rosso solo per il div principale
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '1.875rem',
        padding: isMobile ? '1rem' : '2rem',
        zIndex: 3000,
        maxHeight: '90vh',
        overflowY: 'auto',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Header con titolo e bottone di chiusura */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Scegli un Agente
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Sezione Primary Agents */}
      <Grid container spacing={2}>
        {primaryAgents.map((agent, index) => (
          <Grid item xs={12} sm={6} md={3} key={agent.agentKey}>
            <Paper
              elevation={3}
              sx={{
                padding: '0.75rem',
                borderRadius: '1rem',
                textAlign: 'center',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'background-color 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)', // Effetto hover simile a AgentSwitch
                },
                // Limita l'altezza massima per evitare sovrapposizioni
                maxHeight: '12rem',
              }}
              onClick={() => {
                handleNewChat(agent.agentKey);
                onClose();
              }}
            >
              <Box
                component="img"
                src={agent.image}
                alt={agent.name}
                sx={{
                  width: '3.3rem',
                  height: '3.3rem',
                  margin: '0 auto 0rem',
                }}
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: '0.1rem', fontSize: '0.9rem' }}>
                {agent.name}
              </Typography>
              <Typography variant="body2" sx={{ color: '#6B7280', flexGrow: 1, fontSize: '0.75rem' }}>
                {agent.description}
              </Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // Previene la propagazione del click al Paper
                  handleNewChat(agent.agentKey);
                  onClose();
                }}
                sx={{
                  marginTop: '0.4rem',
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1rem',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  padding: '0.5rem 1rem',
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                Inizia
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Divider */}
      <Box sx={{ my: 2 }}>
        <Divider />
      </Box>

      {/* Sezione Secondary Agents */}
      <Grid container spacing={2} sx={{ paddingBottom: '1rem' }}>
        {secondaryAgents.map((agent) => (
          <Grid item xs={12} sm={6} md={6} key={agent.agentKey}>
            <Paper
              elevation={3}
              sx={{
                padding: '0.75rem',
                borderRadius: '1rem',
                textAlign: 'center',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'background-color 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)', // Effetto hover simile a AgentSwitch
                },
                maxHeight: '12rem', // Limita l'altezza massima
              }}
              onClick={() => {
                handleNewChat(agent.agentKey);
                onClose();
              }}
            >
              <Box
                component="img"
                src={agent.image}
                alt={agent.name}
                sx={{
                  width: '2.5rem',
                  height: '2.5rem',
                  margin: '0 auto 0.75rem',
                }}
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: '0.25rem', fontSize: '0.9rem' }}>
                {agent.name}
              </Typography>
              <Typography variant="body2" sx={{ color: '#6B7280', flexGrow: 1, fontSize: '0.75rem' }}>
                {agent.description}
              </Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // Previene la propagazione del click al Paper
                  handleNewChat(agent.agentKey);
                  onClose();
                }}
                sx={{
                  marginTop: '0.75rem',
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1rem',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  padding: '0.5rem 1rem',
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                Inizia
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AgentStart;
