import React, { useState } from 'react';
import './styles/Services.css'; // Assicurati che il percorso sia corretto

const quizIcon = "/home_icon/quiz.png";
const activityIcon = "/home_icon/attività.png";
const labIcon = "/home_icon/laboratorio.png";
const writingIcon = "/home_icon/writing.png";
const methodologyIcon = "/home_icon/metodologie.png";

const services = [
  {
    title: 'Attività didattiche',
    description: 'Pianifica attività educative innovative e coinvolgenti.',
    details: 'Progetta attività didattiche che migliorano l’engagement degli studenti utilizzando metodologie innovative. Personalizza ogni attività per rispondere agli obiettivi specifici, massimizzando l’apprendimento e favorendo la partecipazione attiva sia individuale che di gruppo in un contesto scolastico.',
    icon: activityIcon,
    detailsTitle: 'Cosa puoi chiedere a Minerva?',
    customDetails: (
      <div className="chat-message assistant-message">
        <p>
        Puoi creare un'attività di un'ora per la festa dei Nonni per una classe di prima primaria?
        </p>
      </div>
    )
  },
  {
    title: 'Ricerca Metodologie',
    description: 'Scopri nuove metodologie educative per migliorare l\'insegnamento.',
    details: 'Esplora metodologie didattiche innovative e risorse aggiornate per migliorare la pratica educativa. Fornisce raccomandazioni su libri, articoli e strumenti digitali per integrare nuove tecniche di insegnamento, favorendo l’adattamento ai bisogni degli studenti e agli obiettivi didattici.',
    icon: methodologyIcon,
    detailsTitle: 'Cosa puoi chiedere a Minerva?',
    customDetails: (
      <div className="chat-message assistant-message">
        <p>
          Richiedi informazioni su nuove tecniche di insegnamento, integrazione di tecnologia in classe e strategie per personalizzare l'apprendimento.
        </p>
      </div>
    )
  },
  {
    title: 'Progetti e Laboratori',
    description: 'Progetta attività e lezioni basate su progetti per l\'apprendimento.',
    details: 'Genera idee per progetti pratici e laboratori che stimolano la creatività e la collaborazione in classe. Le attività sono basate su metodologie moderne e promuovono un apprendimento esperienziale che coinvolge attivamente gli studenti in situazioni reali e simulate.',
    icon: labIcon,
    detailsTitle: 'Cosa puoi chiedere a Minerva?',
    customDetails: (
      <div className="chat-message assistant-message">
        <p>
          Ottieni idee per laboratori scientifici, progetti artistici e attività collaborative che favoriscono l'apprendimento pratico.
        </p>
      </div>
    )
  },
  {
    title: 'Scrittura',
    description: 'Supporta nella scrittura e traduzione di testi educativi.',
    details: 'Assisti gli insegnanti nella riscrittura di testi, ricerca di sinonimi, traduzione e miglioramento del linguaggio. Fornisce strumenti per ottimizzare la chiarezza e la qualità dei contenuti, adattando il testo a diversi livelli di apprendimento e stili educativi.',
    icon: writingIcon,
    detailsTitle: 'Cosa puoi chiedere a Minerva?',
    customDetails: (
      <div className="chat-message assistant-message">
        <p>
          Chiedi aiuto per creare materiali didattici, correggere testi e tradurre contenuti in diverse lingue per adattarli alle esigenze dei tuoi studenti.
        </p>
      </div>
    )
  },
  {
    title: 'Creazione Quiz',
    description: 'Crea quiz interattivi per stimolare il pensiero critico degli studenti.',
    details: 'Sviluppa quiz a risposta multipla che coprono vari argomenti. Le domande sono bilanciate per difficoltà, coinvolgenti e stimolanti, progettate per valutare in modo efficace le conoscenze degli studenti e migliorare il loro apprendimento attraverso la riflessione.',
    icon: quizIcon,
    detailsTitle: 'Cosa puoi chiedere a Minerva?',
    customDetails: (
      <div className="chat-message assistant-message">
        <p>
          Genera quiz personalizzati su vari argomenti, crea domande a risposta multipla e fornisci feedback dettagliati per aiutare gli studenti a migliorare.
        </p>
      </div>
    )
  },
];

const ServicesComponent = () => {
  const [selectedService, setSelectedService] = useState(services[0]); // Imposta il primo servizio di default
  const [animationClass, setAnimationClass] = useState('fade-in');

  const handleServiceClick = (service) => {
    if (service !== selectedService) { // Se è un nuovo servizio, cambialo
      setAnimationClass('fade-out');
      setTimeout(() => {
        setSelectedService(service);
        setAnimationClass('fade-in');
      }, 300);
    }
  };

  return (
    <div className="services-section">
      <div className="services-intro">
        <div className="services-intro-text">
          <h1>
            Tutti gli strumenti per
            <br />
            fare del tuo meglio.
          </h1>
          <p>
            Scopri come Minerva semplifica la didattica,
            <br />
            offrendo strumenti personalizzati per
            <br />
            migliorare l’insegnamento e coinvolgere ogni
            <br />
            studente.
          </p>
        </div>
      </div>
      <div className="services-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className={`service-card ${selectedService === service ? 'active' : ''}`}
            onClick={() => handleServiceClick(service)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleServiceClick(service);
              }
            }}
            aria-pressed={selectedService === service}
          >
            <h2 className="service-title">{service.title}</h2>
            <img src={service.icon} alt={`${service.title} icon`} className="service-icon" />
          </div>
        ))}
      </div>

      <hr className="services-divider" />

      <div className="details-section">
        <div className="details-left">
          <h2 className="details-title">
            {selectedService ? selectedService.detailsTitle : 'Titolo Generico'}
          </h2>
          {selectedService ? selectedService.customDetails : (
            <div className="chat-message assistant-message">
              <p>Descrizione Generica</p>
            </div>
          )}
        </div>
        <div className="details-right">
          {selectedService && (
            <div className={`service-details ${animationClass}`}>
              <h3 className="service-details-title">{selectedService.title}</h3>
              <p className="service-details-text">{selectedService.details}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesComponent;
