import React from 'react';
import './styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="cta-button">
          <a href="/chat">
            <button>Prova Minerva</button>
          </a>
        </div>

        <div className="footer-image">
          <img src="/footer/book.png" alt="Libri" />
        </div>

        <div className="contact-info">
          <p className="contact-title">Contatti</p>
          <p className="contact-email">minervaeducationlab@gmail.com</p>
          <div className="social-icons">
            {/* Link per TikTok */}
            <a 
              href="https://www.tiktok.com/@minervaeducation?_t=8pqeFV9WSwr&_r=1" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/footer/tiktok.png" alt="TikTok" />
            </a>

            {/* Link per Instagram */}
            <a 
              href="https://www.instagram.com/minervaeducationai?igsh=aXRobzNkcGk1amc4&utm_source=qr" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/footer/instagram.png" alt="Instagram" />
            </a>

            {/* Link per LinkedIn */}
            <a 
              href="https://www.linkedin.com/in/minervaeducationai" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/footer/linkedin.png" alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
