// agentButton.js
import React, { useEffect, useRef } from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AgentSwitch = ({
  didatticaOpen,
  setDidatticaOpen,
  selectedOption,
  handleOptionChange,
}) => {
  const options = [
    {
      key: 'quiz',
      title: 'Quiz',
      description:
        'Perfetto per creare quiz stimolanti e mirati per diversi livelli di apprendimento.',
    },
    {
      key: 'laboratori',
      title: 'Laboratorio e Progetti',
      description:
        'Perfetto per ideare e sviluppare laboratori o progetti stimolanti',
    },
    {
      key: 'attività',
      title: 'Attività',
      description:
        'Progetta attività didattiche con metodologie innovative per la classe.',
    },
    {
      key: 'metodologie',
      title: 'Metodologie',
      description:
        'Scopri nuove metodologie educative, con consigli su risorse e spiegazioni.',
    },
  ];

  const containerRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDidatticaOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, setDidatticaOpen]);

  return (
    <Box ref={containerRef} sx={{ position: 'relative', display: 'inline-block' }}>
      <Button
        variant="contained"
        onClick={() => setDidatticaOpen(!didatticaOpen)}
        endIcon={<ExpandMoreIcon sx={{ marginLeft: '-0.6rem' }} />}
        sx={{
          backgroundColor: didatticaOpen ? '#E57373' : '#DF4634',
          color: '#FFFFFF',
          borderRadius: '1.875rem',
          width: '7rem',
          height: '2.2rem',
          position: isMobile ? 'relative' : 'fixed',
          left: isMobile ? 'auto' : '18%',
          top: isMobile ? 'auto' : '2.5rem',
          textTransform: 'none',
          fontSize: '0.875rem',
          fontWeight: 500,
          textAlign: 'center',
          zIndex: 1600,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          '&:hover': {
            backgroundColor: '#E57373',
          },
        }}
      >
        {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
      </Button>

      {didatticaOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: isMobile ? '3.5rem' : '5rem',
            left: isMobile ? '50%' : '-0.9rem',
            transform: isMobile ? 'translateX(-50%)' : 'none',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '1.25rem',
            width: '18rem',
            zIndex: isMobile ? 1 : 2000,
            padding: '0.5rem 0.75rem',
            border: '1px solid #DF4634',
          }}
        >
          {options.map((option) => (
            <Box
              key={option.key}
              onClick={() => {
                handleOptionChange(option.key);
                setDidatticaOpen(false);
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                backgroundColor:
                  selectedOption === option.key
                    ? 'rgba(223, 70, 52, 0.1)'
                    : '#FFFFFF',
                borderRadius: '1.25rem',
                padding: '0.5rem',
                marginBottom: '0.25rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                },
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#000000',
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                  textAlign: 'left',
                }}
              >
                {option.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#666666',
                  fontSize: '0.75rem',
                  textAlign: 'left',
                }}
              >
                {option.description}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AgentSwitch;
