// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Login from './components/Login';
import WelcomePage from './components/WelcomePage';
import Signup from './components/Signup';
import Chat from './components/chat/Chat';
import Profile from './components/profile/Profile';
import Subscription from './components/Subscription';
import ResetPassword from './components/ResetPassword'; // Importa il componente ResetPassword
import CheckoutSuccess from './components/CheckoutSuccess';
import CheckoutCancel from './components/CheckoutCancel';
import ChangePassword from './components/ChangePassword'; 
import DocumentViewer from './components/DocumentViewer';


import './App.css';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/welcomepage" element={<WelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/abbonamento" element={<Subscription />} />
        <Route path="/checkout-success" element={<CheckoutSuccess />} />
        <Route path="/checkout-cancel" element={<CheckoutCancel />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/documents/:documentType" element={<DocumentViewer />} />
      </Routes>
    </div>
  );
};

export default App;
