// MessageInput.js
import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close'; 
import useGetDocuments from '../../api/documents/useGetDocuments'; 
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; 

const MessageInput = ({
  newMessage,
  setNewMessage,
  handleSendMessage,
  files,
  setFiles,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { documents, loading } = useGetDocuments(); 

  const [anchorEl, setAnchorEl] = useState(null); 
  const [showDocumentList, setShowDocumentList] = useState(false); 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectFromProfile = () => {
    setShowDocumentList(true);
    handleClose(); 
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    console.log('Files selected:', selectedFiles); 
    selectedFiles.forEach((file) => {
      console.log('File:', file.name, 'Type:', file.type); 
    });
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const getFileIcon = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon color="error" />;
      case 'doc':
      case 'docx':
        return <InsertDriveFileIcon style={{ color: '#3F51B5' }} />; 
      case 'txt':
        return <InsertDriveFileIcon style={{ color: '#4CAF50' }} />; 
      default:
        return <AttachFileIcon />;
    }
  };

  const handleSelectDocument = async (doc) => {
    try {
      console.log('File URL:', doc.file_url);
  
      // Effettua una richiesta fetch per ottenere il contenuto del file
      const response = await fetch(doc.file_url);
      if (!response.ok) {
        throw new Error('Errore nel fetch del file dal profilo');
      }
      const blob = await response.blob();
  
      // Ottieni l'estensione dal nome del file
      const extensionMatch = doc.document_name.match(/\.[0-9a-z]+$/i);
      const extension = extensionMatch ? extensionMatch[0] : '';
  
      // Se document_name non ha estensione, aggiungila dal blob.type
      let fileName = doc.document_name;
      if (!extension) {
        // Estrazione dell'estensione dal tipo MIME
        const mimeToExtension = {
          'application/pdf': '.pdf',
          'application/msword': '.doc',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
          'text/plain': '.txt',
        };
        const inferredExtension = mimeToExtension[blob.type] || '';
        fileName = `${doc.document_name}${inferredExtension}`;
      }
  
      // Crea un oggetto File utilizzando il blob ottenuto
      const file = new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
  
      // Aggiungi il file all'array files
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, file];
        console.log('Files after adding:', updatedFiles);
        return updatedFiles;
      });
  
      // Chiudi la lista dei documenti salvati
      setShowDocumentList(false);
    } catch (error) {
      console.error('Errore nel caricamento del file dal profilo:', error);
      alert('Errore nel caricamento del file dal profilo. Riprova più tardi.');
    }
  };
  

  return (
    <Box
      component="form"
      onSubmit={handleSendMessage}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        backgroundColor: '#FFFFFF',
        borderRadius: '1.875rem',
        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        width: isMobile ? '100%' : 'calc(100% - 8.75rem)',
        maxWidth: '100%',
        margin: '0 auto',
        marginTop: '0.65rem',
        position: 'relative',
        zIndex: 1,
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {files.length > 0 && (
        <Box sx={{ width: '100%', padding: '0.625rem', overflowY: 'auto' }}>
          {files.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.5rem',
                justifyContent: 'space-between',
                backgroundColor: '#f5f5f5',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {getFileIcon(file)}
                <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
                  {file.name}
                </Typography>
              </Box>
              <Button
                size="small"
                color="error"
                onClick={() => handleRemoveFile(file.name)}
              >
                Rimuovi
              </Button>
            </Box>
          ))}
        </Box>
      )}

      {/* Lista documenti salvati dal profilo */}
      {showDocumentList && (
        <Box
          sx={{
            width: '100%',
            padding: '0.625rem',
            backgroundColor: '#f5f5f5',
            borderRadius: '1rem',
            position: 'relative',
            marginBottom: '1rem',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            onClick={() => setShowDocumentList(false)} // Chiude la lista
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" sx={{ marginBottom: '1rem', textAlign: 'center' }}>
            Documenti Salvati
          </Typography>

          {loading ? (
            <Typography>Caricamento documenti...</Typography>
          ) : (
            <List>
              {documents.map((doc) => (
                <ListItem
                  key={doc.id}
                  button
                  onClick={() => handleSelectDocument(doc)}
                >
                  <ListItemIcon>
                    {getFileIcon({ name: doc.document_name })}
                  </ListItemIcon>
                  <ListItemText primary={doc.document_name} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <IconButton onClick={handleClick}>
          <AttachFileIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => document.getElementById('file-input').click()}>
            Carica dal PC
          </MenuItem>
          <MenuItem onClick={handleSelectFromProfile}>Seleziona dal Profilo</MenuItem>
        </Menu>
        <input
          accept=".txt,.pdf,.docx,.doc"
          style={{ display: 'none' }}
          id="file-input"
          multiple
          type="file"
          onChange={handleFileSelect}
        />

        <TextField
          fullWidth
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Scrivi un messaggio a MinervaAI..."
          autoComplete="off"
          sx={{
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '1.875rem',
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
        />

        <Button
          type="submit"
          sx={{
            marginLeft: '0.3rem',
            marginRight: '0.625rem',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            width: '1.875rem',
            height: '1.875rem',
            minWidth: '1.875rem',
            '& .MuiButton-label': {
              justifyContent: 'center',
            },
          }}
        >
          <img
            src="/chat_icon/send_icon.png"
            alt="Invia"
            style={{ width: '1.25rem', height: '1.25rem' }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default MessageInput;
