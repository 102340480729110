// src/components/SubscriptionPlan.js
import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

const SubscriptionPlan = ({ title, features, price, onPurchase, isActive, endDate, isMonthlyActive }) => {
  return (
    <Box
      sx={{
        width: { xs: '100%', md: '30%' },
        background: isActive ? '#62140B' : 'white',
        boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '1.875rem',
        padding: '1.5rem', // Ridotto da 2rem a 1.5rem
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        minHeight: 'auto', // Rimuovi l'altezza minima
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: '1.5rem', // Ridotto da 2rem a 1.5rem
          textAlign: 'center',
          color: isActive ? '#FFFFFF' : '#1D1F23',
          fontSize: '1.25rem', // Ridotto da 1.4375rem a 1.25rem
          fontFamily: 'Inter',
          fontWeight: 500,
          lineHeight: '1.2rem', // Ridotto da 1rem a 1.2rem
          wordWrap: 'break-word',
        }}
      >
        {title}
      </Typography>

      <List sx={{ padding: 0 }}>
        {features.map((feature, index) => (
          <ListItem key={index} sx={{ paddingLeft: 0, paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '0.5rem', color: isActive ? '#FFFFFF' : '#2B2E34' }}>
              {React.cloneElement(feature.icon, { fontSize: 'small' })} {/* Ridotto fontSize */}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body2" // Cambiato da body1 a body2
                  sx={{
                    color: isActive ? '#FFFFFF' : '#2B2E34',
                    fontSize: '0.875rem', // Ridotto da 1.25rem a 0.875rem
                    fontFamily: 'Inter',
                    fontWeight: 300,
                    lineHeight: '1.2rem', // Ridotto da 1.875rem a 1.2rem
                    wordWrap: 'break-word',
                  }}
                >
                  {feature.text}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h6"
        sx={{
          marginBottom: '1.5rem', // Ridotto da 2rem a 1.5rem
          marginTop: '0.75rem', // Ridotto da 1rem a 0.75rem
          textAlign: 'center',
          color: isActive ? '#FFFFFF' : '#1D1F23',
          fontSize: '1.25rem', // Ridotto da 1.4375rem a 1.25rem
          fontFamily: 'Inter',
          fontWeight: 900,
          lineHeight: '1.2rem', // Ridotto da 1rem a 1.2rem
          wordWrap: 'break-word',
        }}
      >
        {price}
      </Typography>

      {isActive && endDate && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: '0.75rem', // Ridotto da 1rem a 0.75rem
            textAlign: 'center',
            color: '#FFFFFF',
            fontSize: '0.875rem', // Ridotto da 1rem a 0.875rem
            fontFamily: 'Inter',
            fontWeight: 600,
            lineHeight: '1.2rem', // Ridotto da 1.5rem a 1.2rem
          }}
        >
          Scade il: {new Date(endDate).toLocaleDateString()}
        </Typography>
      )}

      <Button
        variant="contained"
        onClick={onPurchase}
        sx={{
          width: '100%',
          backgroundColor: isActive ? '#F3F6FB !important' : '#2B2E34',
          color: isActive ? '#62140B !important' : '#FFFFFF',
          boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
          borderRadius: '1.875rem',
          padding: '0.75rem', // Ridotto da 1rem a 0.75rem
          fontSize: '0.875rem', // Ridotto da 1rem a 0.875rem
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: isActive ? '#F3F6FB' : '#1e1f22',
          },
        }}
        disabled={isActive}
      >
        {isActive ? (
          <Typography
            sx={{
              color: 'rgba(98, 20, 11, 0.68)',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontFamily: 'Inter',
              fontSize: '0.875rem', // Ridotto da 23px a 0.875rem
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1rem',
            }}
          >
            Piano Attivo
          </Typography>
        ) : (
          isMonthlyActive ? 'Passa ad Annuale' : 'Acquista'
        )}
      </Button>
    </Box>
  );
};

SubscriptionPlan.propTypes = {
  title: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  price: PropTypes.string.isRequired,
  onPurchase: PropTypes.func,
  isActive: PropTypes.bool,
  endDate: PropTypes.string,
  isMonthlyActive: PropTypes.bool, // Aggiungi la nuova prop
};

SubscriptionPlan.defaultProps = {
  onPurchase: () => {},
  isActive: false,
  endDate: null,
  isMonthlyActive: false, // Imposta il default
};

export default SubscriptionPlan;
