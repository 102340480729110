// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, Button } from '@mui/material';
import Sidebar from './Sidebar';
import ChatMessages from './ChatMessages';
import MessageInput from './MessageInput';
import AgentSwitch from './agentSwitch';
import MobileHeader from './MobileHeader';
import MobileSidebar from './MobileSidebar';
import AgentStart from './AgentStart'; 
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeTokens,
} from '../../api/authUtils';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Definisci le frasi iniziali per ogni agente
const initialMessages = {
  'quiz': 'Ciao! Sono l\'agente Quiz. Posso creare quiz didattici interattivi per la tua classe. Per favore, indicami classe, livello, argomento e obiettivo specifico, così posso aiutarti al meglio. ✏️',
  'laboratori': 'Ciao! Sono l\'agente Laboratori. Posso ideare laboratori coinvolgenti per i tuoi studenti. Specifica il livello, l\'argomento e i materiali disponibili per ottenere la proposta ideale. 🔍',
  'attività': 'Salve! Sono l\'agente Attività. Creiamo insieme attività didattiche innovative. Indica classe, argomento e obiettivi, così posso offrirti la proposta più adatta. 💡',
  'metodologie': 'Buongiorno! Sono l\'agente Metodologie. Ti aiuterò a scoprire nuove metodologie educative. Dimmi il contesto e l\'argomento per trovare la metodologia perfetta per le tue esigenze. 📘',
  'montessori': 'Benvenuto! Sono Maria Montessori. Posso spiegarti il mio metodo educativo e come applicarlo. Specifica età, obiettivi e necessità dei tuoi alunni per consigli personalizzati. 🌼',
  'malaguzzi': 'Ciao! Sono Loris Malaguzzi. Ti aiuterò a esplorare il Reggio Emilia Approach. Forniscimi dettagli sugli studenti e sulle attività che vorresti sviluppare per ottenere consigli mirati. 🌱',
};


// Definisci le immagini per Montessori e Malaguzzi
const agentImages = {
  'montessori': '/people/montessori.png',
  'malaguzzi': '/people/malaguzzi.png',
};

const Chat = () => {
  // State variables
  const [chats, setChats] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [didatticaOpen, setDidatticaOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('attività');
  const [agentStartOpen, setAgentStartOpen] = useState(false); // New state variable

  const navigate = useNavigate();
  const containerRef = useRef(null);

  // State for mobile sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width:600px)');

  // Function to refresh the access token
  const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      removeTokens();
      navigate('/login');
      return null;
    }

    try {
      const response = await fetch(`${baseUrl}/api/accounts/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      setAccessToken(data.access);
      if (data.refresh) {
        setRefreshToken(data.refresh);
      }
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeTokens();
      navigate('/login');
      return null;
    }
  };

  // Function to make authenticated requests
  const makeAuthenticatedRequest = async (url, options = {}) => {
    let accessToken = getAccessToken();

    // If no access token, redirect to login
    if (!accessToken) {
      navigate('/login');
      return null;
    }

    // Set the Authorization header
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      let response = await fetch(url, { ...options, headers });

      // If unauthorized, try to refresh the token
      if (response.status === 401 || response.status === 403) {
        accessToken = await refreshAccessToken();
        if (!accessToken) {
          return null;
        }

        // Retry the original request with the new token
        const retryHeaders = {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        };
        response = await fetch(url, { ...options, headers: retryHeaders });
      }

      return response;
    } catch (error) {
      console.error('Error making authenticated request:', error);
      return null;
    }
  };

  // Fetch chats on component mount
  useEffect(() => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      navigate('/login');
      return;
    }

    fetchChats(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  // Auto-scroll when messages change
  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [messages, selectedChat]);

  // Fetch chats from API
  const fetchChats = async (token) => {
    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/`, {
        method: 'GET',
      });

      if (!response) return;

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching chats:', errorData);
        return;
      }

      const data = await response.json();
      const sortedChats = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setChats(sortedChats);

      const currentChat = sortedChats.find((c) => c.id === selectedChat?.id);
      if (currentChat && selectedChat?.id !== currentChat.id) {
        selectChat(currentChat);
      } else if (sortedChats.length > 0 && !currentChat) {
        selectChat(sortedChats[0]);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  // Fetch messages for a specific chat and agent
  const fetchMessages = async (chatId, agent) => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${chatId}/messages/${agent}/?include=messages`,
        {
          method: 'GET',
        }
      );

      if (!response) {
        setLoading(false);
        return;
      }

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        setLoading(false);
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching messages:', errorData);
        setLoading(false);
        return;
      }

      const data = await response.json();
      let fetchedMessages = data.messages;

      // Se non ci sono messaggi, aggiungi il messaggio iniziale
      if (fetchedMessages.length === 0) {
        const initialMessageText = initialMessages[agent] || 'Benvenuto!';
        fetchedMessages = [
          {
            id: 'initial', // ID unico per il messaggio iniziale
            chat_id: chatId,
            text: initialMessageText,
            sender: 'assistant',
          },
        ];
      }

      setMessages(fetchedMessages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };

  // Create a new chat with a specific agent
  const handleNewChat = async (agentOption = selectedOption, fromAgentStart = false) => {
    setSelectedOption(agentOption); // Update the selected option
    // setAgentStartOpen(true);
    // return;
    if (!fromAgentStart && agentOption === selectedOption) {
      setAgentStartOpen(true);
      return;
    }

    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ agent: agentOption }),
      });

      if (!response) return;

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error creating new chat:', errorData);
        return;
      }

      const data = await response.json();
      setChats([data, ...chats]);
      selectChat(data);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  // Delete a specific chat
  const handleDeleteChat = async (chatId) => {
    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/${chatId}/delete/`, {
        method: 'DELETE',
      });

      if (!response) return;

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        return;
      }

      if (response.ok) {
        const updatedChats = chats.filter((chat) => chat.id !== chatId);
        setChats(updatedChats);
        if (updatedChats.length > 0) {
          selectChat(updatedChats[0]);
        } else {
          setSelectedChat(null);
          setMessages([]);
        }
      } else {
        console.error('Error deleting chat');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  // Send a new message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && files.length === 0) return;
    if (!selectedChat) return;

    const accessToken = getAccessToken();
    if (!accessToken) return;

    const userMessage = {
      id: Date.now(),
      chat_id: selectedChat.id,
      text: newMessage,
      sender: 'user',
      attachments: files.map((file) => ({
        file_name: file.name,
      })),
    };

    // Add user message and assistant placeholder
    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      {
        id: Date.now() + 1,
        chat_id: selectedChat.id,
        text: '',
        sender: 'assistant',
        isTyping: true,
      },
    ]);
    setNewMessage('');
    setFiles([]);

    try {
      const formData = new FormData();
      formData.append('text', newMessage);

      files.forEach((file) => {
        if (file.blob) {
          formData.append('files', file.blob, file.name);
        } else {
          formData.append('files', file);
        }
      });
      

      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${selectedChat.id}/messages/${selectedOption}/`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response) return;

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error sending message:', errorData);
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      if (!response.body) {
        console.error('Response body is not readable');
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let agentMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const cleanedChunk = chunk.replace(/data:/g, '').replace(/\?_?/g, ' ');
        agentMessage += cleanedChunk;

        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage && lastMessage.sender === 'assistant') {
            return [
              ...prevMessages.slice(0, -1),
              {
                ...lastMessage,
                text: agentMessage,
                isTyping: true,
              },
            ];
          } else {
            return [
              ...prevMessages,
              {
                id: Date.now(),
                chat_id: selectedChat.id,
                text: agentMessage,
                sender: 'assistant',
                isTyping: true,
              },
            ];
          }
        });
      }

      // After reading all chunks, set isTyping to false
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'assistant') {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              text: agentMessage,
              isTyping: false,
            },
          ];
        } else {
          return prevMessages;
        }
      });

      await fetchChats(accessToken);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    }
  };

  // Handle option change (agent)
  const handleOptionChange = async (option) => {
    setSelectedOption(option);
    setDidatticaOpen(false);
    await handleNewChat(option);
  };

  // Select a specific chat
  const selectChat = (chat) => {
    if (selectedChat?.id !== chat.id) {
      setSelectedChat(chat);
      setSelectedOption(chat.agent); // Update the selected option based on chat agent
    }
  };

  // Fetch messages when selected chat or agent changes
  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id, selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat, selectedOption]);

  // Handle user logout
  const handleLogout = () => {
    removeTokens();
    navigate('/login');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: '100vh',
        backgroundColor: '#F3F6FB',
        fontFamily: 'Inter, sans-serif',
        position: 'relative', // Ensure relative positioning for overlays
      }}
    >
      {isMobile ? (
        <>
          <MobileHeader
            didatticaOpen={didatticaOpen}
            setDidatticaOpen={setDidatticaOpen}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
            handleNewChat={handleNewChat}
          />
          {sidebarOpen && (
            <MobileSidebar
              chats={chats}
              selectedChat={selectedChat}
              selectChat={selectChat}
              handleNewChat={handleNewChat}
              handleDeleteChat={handleDeleteChat}
              handleLogout={handleLogout}
              navigate={navigate}
              selectedOption={selectedOption}
              onClose={() => setSidebarOpen(false)}
            />
          )}
        </>
      ) : (
        <>
          <Sidebar
            chats={chats}
            selectedChat={selectedChat}
            selectChat={selectChat}
            handleNewChat={handleNewChat}
            handleDeleteChat={handleDeleteChat}
            handleLogout={handleLogout}
            navigate={navigate}
            selectedOption={selectedOption}
          />

          {selectedOption === 'montessori' || selectedOption === 'malaguzzi' ? (
            <Box
              sx={{
                position: 'fixed',
                left: '18%',
                top: '2.5rem',
                display: 'flex',
                alignItems: 'center',
                zIndex: 2000,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1.875rem',
                  width: '7rem',
                  height: '2.2rem',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
              </Button>
            </Box>
          ) : (
            <AgentSwitch
              didatticaOpen={didatticaOpen}
              setDidatticaOpen={setDidatticaOpen}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          )}
        </>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: isMobile ? '1rem' : '3.125rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ChatMessages
          messages={messages}
          loading={loading}
          containerRef={containerRef}
          chatTitle={selectedChat?.title || 'Chat'}
          selectedOption={selectedOption} // Pass selectedOption to ChatMessages
        />
        <MessageInput
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSendMessage={handleSendMessage}
          files={files}
          setFiles={setFiles}
        />

        {/* Sovrapposizione bianca quando il sottomenu AgentSwitch è attivo */}
        {didatticaOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)', 
              zIndex: 1500, 
              pointerEvents: 'none',
            }}
          />
        )}

        {/* Sovrapposizione bianca quando AgentStart è attivo */}
        {agentStartOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-trasparente
              zIndex: 1700, // Assicurati che sia sopra tutti gli altri elementi tranne AgentStart
              pointerEvents: 'none', // Permette di cliccare attraverso l'overlay se necessario
            }}
          />
        )}

        {/* AgentStart component */}
        {agentStartOpen && (
          <AgentStart
            open={agentStartOpen}
            onClose={() => setAgentStartOpen(false)}
            handleNewChat={(agentKey) => {
              setAgentStartOpen(false);
              handleNewChat(agentKey, true); // Passa true per indicare che proviene da AgentStart
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Chat;
