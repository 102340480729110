// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/Header.css';
import PropTypes from 'prop-types';
import useDeleteAccount from '../api/profile/useDeleteAccount';
import useSupportEmail from '../api/auth/useSupportEmail';
import apiClient from '../api/apiClient';
import { removeTokens } from '../api/authUtils';

// Importa i nuovi componenti
import DeleteAccountModal from './DeleteAccountModal';
import SupportModal from './SupportModal';

// Importa il nuovo hook per il Customer Portal
import useCustomerPortal from '../api/subscription/useCustomerPortal';

const Header = ({
  isLoggedIn,
  setIsLoggedIn,
  mobileMenuOpen,
  setMobileMenuOpen,
  backgroundColor = 'white',
  textColor = '#2b2e34',
  logoImage = '/logo/logogrey.png',
}) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  const { deleteAccount, loading: deleteLoading, error: deleteError, success: deleteSuccess } = useDeleteAccount();

  const {
    sendSupportEmail,
    loading: supportLoading,
    error: supportError,
    message: supportMessage,
  } = useSupportEmail();

  // Utilizza il nuovo hook
  const { openCustomerPortal, loading: portalLoading, error: portalError } = useCustomerPortal();

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await apiClient.get('/api/accounts/profile/');
        setUserEmail(response.data.email);
      } catch (error) {
        console.error('Errore nel recupero dell\'email utente:', error);
      }
    };

    if (isLoggedIn) {
      fetchUserEmail();
    }
  }, [isLoggedIn]);

  const handleLogout = () => {
    removeTokens();
    setIsLoggedIn(false);
    navigate('/', { replace: true });
  };

  const handleProfileClick = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleAccountDelete = async () => {
    try {
      await deleteAccount();
      setIsLoggedIn(false);
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Errore nella cancellazione dell\'account:', err);
    }
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();

    const message = e.target.message.value;

    if (!message) {
      return;
    }

    try {
      await sendSupportEmail(message);
      e.target.reset();
      setTimeout(() => {
        setSupportModalOpen(false);
      }, 2000);
    } catch (err) {
      // L'errore è già gestito nel custom hook
    }
  };

  return (
    <header className="header" style={{ backgroundColor }}>
      <div className="container">
        <div className="logo-container">
          <div className="logo-text" style={{ color: textColor }}>
            Minerva
          </div>
          <img
            src={logoImage}
            alt="Minerva Logo"
            className={`logo-image ${logoImage === '/logo/logobianco.png' ? 'logo-image-white' : ''}`}
          />
        </div>

        {/* Icona hamburger per il menu mobile */}
        <div className="hamburger-icon" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <img src="/menu_icon/menu.png" alt="Menu" />
        </div>

        {/* Menu di navigazione */}
        <nav className="menu">
          <Link to="/" style={{ color: textColor }}>
            Home
          </Link>
          <Link to="/tutorial" style={{ color: textColor }}>
            Tutorial
          </Link>
          <Link to="/abbonamento" style={{ color: textColor }}>
            Abbonamento
          </Link>
        </nav>

        <div className="user-actions">
          <Link to="/chat" className="ai-link" style={{ color: textColor }}>
            Chat AI
          </Link>
          <div className="divider" style={{ backgroundColor: textColor }}></div>

          {isLoggedIn ? (
            <div className="profile-menu">
              <div className="profile-link" onClick={handleProfileClick}></div>

              {profileMenuOpen && (
                <div className="profile-dropdown">
                  <Link to="/profile" className="profile-item">
                    <img src="/menu_icon/profile_white.png" alt="Profile Icon" className="profile-item-icon" />
                    Profilo
                  </Link>
                  <Link to="/change-password" className="profile-item">
                    <img src="/menu_icon/change_password.png" alt="Change Password Icon" className="profile-item-icon" />
                    Cambia password
                  </Link>
                  {/* Modifica "Cambia carta" per usare il Customer Portal */}
                  <div
                    className="profile-item"
                    onClick={() => {
                      openCustomerPortal();
                      setProfileMenuOpen(false);
                    }}
                  >
                    <img src="/menu_icon/card_white.png" alt="Change Card Icon" className="profile-item-icon" />
                    Cambia carta
                  </div>
                  {/* Elimina account */}
                  <div
                    className="profile-item"
                    onClick={() => {
                      setDeleteModalOpen(true);
                      setProfileMenuOpen(false);
                    }}
                  >
                    <img src="/menu_icon/delete_account.png" alt="Delete Account Icon" className="profile-item-icon" />
                    Elimina account
                  </div>
                  {/* Pulsante "Hai problemi?" */}
                  <div
                    className="profile-item"
                    onClick={() => {
                      setSupportModalOpen(true);
                      setProfileMenuOpen(false);
                    }}
                  >
                    <img src="/menu_icon/questions.png" alt="Questions Icon" className="profile-item-icon" />
                    Hai problemi?
                  </div>
                  {/* Esci */}
                  <div className="profile-item" onClick={handleLogout}>
                    <img src="/menu_icon/exit_white.png" alt="Logout Icon" className="profile-item-icon" />
                    Esci
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link to="/welcomepage" className="login-link" style={{ color: textColor }}>
              Accedi
            </Link>
          )}
        </div>
      </div>

      {/* Modale di conferma eliminazione account */}
      <DeleteAccountModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleAccountDelete}
        loading={deleteLoading}
        error={deleteError}
        success={deleteSuccess}
      />

      {/* Modale di supporto */}
      <SupportModal
        isOpen={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
        onSubmit={handleSupportSubmit}
        loading={supportLoading}
        error={supportError}
        message={supportMessage}
        userEmail={userEmail}
      />
    </header>
  );
};

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  logoImage: PropTypes.string,
};

export default Header;
