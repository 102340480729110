// src/api/authUtils.js
export const getAccessToken = () => {
    return localStorage.getItem('accessToken') || localStorage.getItem('access');
  };
  
  export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken') || localStorage.getItem('refresh');
  };
  
  export const setAccessToken = (token) => {
    localStorage.setItem('accessToken', token);
  };
  
  export const setRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
  };
  
  export const removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
  };
  