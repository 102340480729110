// src/components/Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import useRegister from '../api/auth/useRegister'; // Importa il hook useRegister

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teachingSubject, setTeachingSubject] = useState('');
  const [school, setSchool] = useState('');
  const [currentClassType, setCurrentClassType] = useState('');
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);  // Stato per nascondere il form

  const navigate = useNavigate();
  const { register, loading, error } = useRegister(); // Destruttura le proprietà dal hook

  const [success, setSuccess] = useState(false); // Stato per messaggio di successo

  const handleContinue = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setSuccess(false); // Resetta lo stato di successo
      // Imposta un errore temporaneo locale
      // Poiché il hook gestisce gli errori, potresti voler creare uno stato separato
      // o modificare il hook per supportare questo caso specifico.
      // Per semplicità, useremo il messaggio di errore globale del hook
      setSuccess(false);
      // Potresti considerare di creare un errore locale qui
      // oppure modificare il hook per gestire questo scenario.
      // In questo esempio, non possiamo impostare direttamente l'errore dal componente
      // quindi lascia l'errore gestito solo dal hook.
      return;
    }
    setShowAdditionalFields(true);
    setSuccess(false); // Resetta lo stato di successo
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const userData = {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      teaching_subject: teachingSubject,
      school,
      current_class_type: currentClassType,
    };

    try {
      const data = await register(userData); // Utilizza il metodo register del hook
      console.log('Success:', data);

      setSuccess(true);
      setFormSubmitted(true); // Nasconde il form e mostra il messaggio
    } catch (error) {
      console.error('Error:', error);
      setSuccess(false);
      // L'errore è già gestito dallo stato del hook
    }
  };

  const handleDone = () => {
    navigate('/');  // Reindirizza alla homepage
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F3F6FB',
        position: 'relative',
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <img
        src="/logo/logonero.png"
        alt="Logo"
        style={{
          width: '83px',
          height: '76px',
          marginBottom: '40px',
        }}
      />

      {/* Se il form è stato inviato con successo, mostra il messaggio di conferma */}
      {formSubmitted ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Registrazione completata!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Controlla la tua email per attivare l'account cliccando sul link di verifica.
          </Typography>
          <Button
            onClick={handleDone}
            variant="contained"
            fullWidth
            sx={{
              width: 320,
              height: 50,
              backgroundColor: '#DF4634',
              borderRadius: '30px',
              textTransform: 'none',
              fontSize: '15px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginTop: '8px',
              '&:hover': {
                backgroundColor: '#DF4634',
              },
            }}
          >
            Fatto
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={showAdditionalFields ? handleSignup : handleContinue}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Crea un account
          </Typography>
          {error && <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>Registrazione completata! Controlla la tua email.</Alert>}
          {!showAdditionalFields && (
            <>
              <TextField
                label="Indirizzo email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Conferma Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading} // Disabilita il bottone durante il caricamento
                sx={{
                  width: 320,
                  height: 50,
                  backgroundColor: '#DF4634',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '30px',
                  textTransform: 'none',
                  fontSize: '15px',
                  lineHeight: '27px',
                  color: '#FFFFFF',
                  marginTop: '8px',
                  '&:hover': {
                    backgroundColor: '#DF4634',
                  },
                }}
              >
                {loading ? 'Continuando...' : 'Continua'}
              </Button>
            </>
          )}
          {showAdditionalFields && (
            <>
              <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                margin="normal"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Cognome"
                variant="outlined"
                fullWidth
                margin="normal"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Materia insegnata"
                variant="outlined"
                fullWidth
                margin="normal"
                value={teachingSubject}
                onChange={(e) => setTeachingSubject(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Scuola"
                variant="outlined"
                fullWidth
                margin="normal"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Tipo di classe corrente"
                variant="outlined"
                fullWidth
                margin="normal"
                value={currentClassType}
                onChange={(e) => setCurrentClassType(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading} // Disabilita il bottone durante il caricamento
                sx={{
                  width: 320,
                  height: 50,
                  backgroundColor: '#DF4634',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '30px',
                  textTransform: 'none',
                  fontSize: '15px',
                  lineHeight: '27px',
                  color: '#FFFFFF',
                  marginTop: '8px',
                  '&:hover': {
                    backgroundColor: '#DF4634',
                  },
                }}
              >
                {loading ? 'Iscrivendosi...' : 'Iscriviti'}
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Signup;
