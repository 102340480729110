// src/components/ChangePassword.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Alert,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import usePasswordChange from '../api/auth/usePasswordChange'; // Importa il hook usePasswordChange

const ChangePassword = () => {
  // Stato per i campi del modulo
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Stato per la visibilità delle password
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Stato per nascondere il form dopo il successo
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();
  const { changePassword, loading, error, message } = usePasswordChange(); // Destruttura le proprietà dal hook

  // Funzione per gestire il cambio della password
  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Validazione del modulo
    if (!oldPassword || !newPassword || !confirmPassword) {
      // Puoi gestire questo errore localmente o lasciare che il backend lo gestisca
      // Qui aggiungiamo un errore locale
      alert('Tutti i campi sono obbligatori.');
      return;
    }

    if (newPassword !== confirmPassword) {
      alert('La nuova password e la conferma non corrispondono.');
      return;
    }

    // Optional: Aggiungi ulteriori validazioni sulla complessità della password
    if (newPassword.length < 8) {
      alert('La nuova password deve avere almeno 8 caratteri.');
      return;
    }

    try {
      await changePassword(oldPassword, newPassword, confirmPassword); // Utilizza il metodo changePassword del hook

      setFormSubmitted(true); // Nasconde il form e mostra il messaggio di successo
    } catch (err) {
      console.error('Errore nel cambio della password:', err);
      // L'errore è già gestito dallo stato del hook
    }
  };

  // Effetto per nascondere i messaggi di feedback dopo 5 secondi
  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        // Resetta i messaggi dopo 5 secondi
      }, 5000); // Nascondi dopo 5 secondi

      return () => clearTimeout(timer);
    }
  }, [message, error]);

  const handleDone = () => {
    navigate('/profile'); // Reindirizza al profilo
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F3F6FB',
        position: 'relative',
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <RouterLink to="/">
        <img
          src="/logo/logo.png"
          alt="Logo"
          style={{
            width: '83px',
            height: '76px',
            marginBottom: '40px',
          }}
        />
      </RouterLink>
      {formSubmitted ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Password Cambiata!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            La tua password è stata cambiata con successo. Verrai reindirizzato al tuo profilo.
          </Typography>
          <Button
            onClick={handleDone}
            variant="contained"
            fullWidth
            sx={{
              width: 320,
              height: 50,
              backgroundColor: '#DF4634',
              borderRadius: '30px',
              textTransform: 'none',
              fontSize: '15px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginTop: '8px',
              '&:hover': {
                backgroundColor: '#DF4634',
              },
            }}
          >
            Fatto
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleChangePassword}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Cambia Password
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>
              {message}
            </Alert>
          )}

          {/* Campo Password Attuale */}
          <TextField
            label="Password Attuale"
            type={showOldPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            sx={{
              width: '320px',
              height: '64px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                '& fieldset': {
                  borderColor: '#DF4634',
                },
                '&:hover fieldset': {
                  borderColor: '#DF4634',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DF4634',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#DF4634',
                fontFamily: 'Inter, sans-serif',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#DF4634',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Campo Nuova Password */}
          <TextField
            label="Nuova Password"
            type={showNewPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{
              width: '320px',
              height: '64px',
              marginTop: '8px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                '& fieldset': {
                  borderColor: '#DF4634',
                },
                '&:hover fieldset': {
                  borderColor: '#DF4634',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DF4634',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#DF4634',
                fontFamily: 'Inter, sans-serif',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#DF4634',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Campo Conferma Nuova Password */}
          <TextField
            label="Conferma Nuova Password"
            type={showConfirmPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{
              width: '320px',
              height: '64px',
              marginTop: '8px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                '& fieldset': {
                  borderColor: '#DF4634',
                },
                '&:hover fieldset': {
                  borderColor: '#DF4634',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DF4634',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#DF4634',
                fontFamily: 'Inter, sans-serif',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#DF4634',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Pulsante di Invio */}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading} // Disabilita il bottone durante il caricamento
            sx={{
              width: 320,
              height: 50,
              backgroundColor: '#DF4634',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '30px',
              textTransform: 'none',
              fontSize: '15px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginTop: '16px',
              '&:hover': {
                backgroundColor: '#DF4634',
              },
            }}
          >
            {loading ? 'Cambiando...' : 'Cambia Password'}
          </Button>
        </Box>
      )}
      {/* Link per tornare al Profilo */}
      {!formSubmitted && (
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          Torna al{' '}
          <Link
            component={RouterLink}
            to="/profile"
            sx={{
              color: '#DF4634',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Profilo
          </Link>
        </Typography>
      )}
      {!formSubmitted}
    </Box>
  );
};

export default ChangePassword;
